import * as React from "react";
import {useRef, useEffect} from 'react';
import Tilt from 'react-parallax-tilt';

import Layout from '../components/layout'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from 'gatsby-plugin-image'

import { parallaxeffectimg, innerelement} from '../components/layout.module.css'



gsap.registerPlugin(ScrollTrigger);

function getPostition(el, height){

    const rect = el.getBoundingClientRect();

    var top = rect.top + window.scrollY;
    
    return top;
    
}

function IndexPage(){
    const catlogo = useRef(null);

    const spanCats = useRef(null);
    const movable0 = useRef(null);
    const movable1 = useRef(null);
    const movable2 = useRef(null);
    const movable3 = useRef(null);
    const movable4 = useRef(null);
    const movable5 = useRef(null);
    const movable6 = useRef(null);

    const scrolling = useRef(null);

    const movable7 = useRef(null);

    const button1 = useRef(null);

    const totarget = useRef(null);

    const div1 = useRef(null);

    const lead1 = useRef(null);
    const lead2 = useRef(null);
    const lead3 = useRef(null);
    const lead4 = useRef(null);

    const lead5 = useRef(null);
    const lead6 = useRef(null);
    const lead7 = useRef(null);
    const lead8 = useRef(null);
    const lead9 = useRef(null);
    const lead10 = useRef(null);
    const lead11 = useRef(null);
    const lead12 = useRef(null);
    const lead13 = useRef(null);
    const lead14 = useRef(null);
    const lead15 = useRef(null);
    const lead16 = useRef(null);

    //entry animations

    useEffect(() => {
        const cl =  catlogo.current;

        const cS =  spanCats.current;
        const m0 =  movable0.current;
        const m1 =  movable1.current;
        const m2 =  movable2.current;
        const m3 =  movable3.current;
        const m4 =  movable4.current;
        const m5 =  movable5.current;
        const m6 =  movable6.current;
        const m7 =  movable6.current;

        const sl =  scrolling.current;
        const b1 =  button1.current;

        const t1 =  totarget.current;

        const d1 =  div1.current;

        const l1 =  lead1.current;
        const l2 =  lead2.current;
        const l3 =  lead3.current;
        const l4 =  lead4.current;

        const l5 =  lead5.current;
        const l6 =  lead6.current;
        const l7 =  lead7.current;
        const l8 =  lead8.current;
        const l9 =  lead9.current;
        const l10 =  lead10.current;
        const l11 =  lead11.current;
        const l12 =  lead12.current;
        const l13 =  lead13.current;
        const l14 =  lead14.current;
        const l15 =  lead15.current;
        const l16 =  lead16.current;

        /* const tl = gsap.timeline()
        let tweet = tl.from(l1,{opacity:0, duration: 2, ease: 'none'})
                    .from(l2,{opacity:0, duration: 1.5, ease: 'none'}, 0.1)
                    .from(l3,{opacity:0, duration: 2, ease: 'none'}, 0.1)
                    .from(l4,{opacity:0, duration: 1.5, ease: 'none'}, 0.1)
                    .to(l1,{x:-50, scale:0.7,  duration: 50, ease: 'none'}, 1)
                    .to(l2,{scale:1.2, duration: 50, ease: 'none'}, 1)
                    .to(l3,{x:-50, duration: 50, ease: 'none'}, 1)
                    .to(l4,{x:50, duration: 50, ease: 'none'}, 1)
                    
        tweet.play(); */
        const tl0 = gsap.timeline()
        .from(l1,{opacity:0.2,  duration: 8, ease: 'none'}, 10)
        .from(l2,{opacity:0.2,  duration: 8, ease: 'none'}, 14)
        .from(d1,{opacity:0.2,  duration: 8, ease: 'none'}, 18)
        
        ScrollTrigger.create({
          animation: tl0,
          trigger: m0,
          start: "top top",
          end: "+=100%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim1"
        });

        const positionOfTarget = getPostition(t1, false);
        const positionOfStart = getPostition(cS, false);
        const tweetvalue = positionOfTarget-positionOfStart;
        const tl2 = gsap.timeline()
        .to(cS,{y: '+=' + tweetvalue, duration: 8, ease: 'none'})
        .to(l1,{y:-250,  duration: 5, ease: 'none'}, 1)
        .to(l2,{y:-250,  duration: 5, ease: 'none'}, 1)
        .to(l3,{y:-250,  duration: 5, ease: 'none'}, 1)
        .to(l4,{y:-250,  duration: 5, ease: 'none'}, 1)
        .to(sl,{opacity:0,  duration: 1, ease: 'none'}, 1)
        
        ScrollTrigger.create({
          animation: tl2,
          trigger: m1,
          start: "+=0%",
          end: "+=100%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim2"
        });

        const tl3 = gsap.timeline()
        .from(l5,{y:550,  duration: 8, ease: 'none'})
        .from(l6,{y:550,  duration: 8, ease: 'none'}, 2)
        .from(l7,{y:550,  duration: 8, ease: 'none'}, 4)
        
        // .to(cS,{y:2000,  duration: 8, ease: 'none'}, 10)
        
        ScrollTrigger.create({
          animation: tl3,
          trigger: m2,
          start: "-=100%",
          end: "+=100%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim3"
        });

        const tl4 = gsap.timeline()
        // .to(cS,{y:2000,  duration: 8, ease: 'none'}, 10)
        
        ScrollTrigger.create({
          animation: tl4,
          trigger: m2,
          start: "+=0%",
          pin: true,
          pinSpacing: false,
          scrub: true,
          markers: true,
          id:"anim4"
        });

        const tl5 = gsap.timeline()
        .to(l5,{opacity:0,  duration: 4, ease: 'none'})
        .to(l6,{opacity:0,  duration: 4, ease: 'none'})
        .to(l7,{opacity:0,  duration: 4, ease: 'none'})
        .from(l8,{opacity:0,  duration: 8, ease: 'none'})
        .from(l9,{opacity:0, y:250,  duration: 4, ease: 'none'})
        .from(l10,{opacity:0, y:250,  duration: 5, ease: 'none'})
        .from(l11,{opacity:0, y:250,  duration: 6, ease: 'none'})
        .from(l12,{opacity:0, y:250,  duration: 7, ease: 'none'})
        .from(b1,{x:-3000,opacity:0,  duration: 12, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl5,
          trigger: m3,
          start: "top top",
          pin: true,
          scrub: true,
          markers: true,
          id:"anim5"
        });


        /* const t22 = gsap.timeline()
        // .to(cS,{y:2000,  duration: 8, ease: 'none'}, 10)
        
        ScrollTrigger.create({
          animation: t22,
          trigger: m3,
          start: "top top",
          pin: true,
          pinSpacing: false,
          scrub: true,
          markers: true,
          id:"anim1000"
        }); */
        /* const tl6 = gsap.timeline()
        .to(m3,{height:100,  duration: 2, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl6,
          trigger: m4,
          start: "-=100%",
          pin: false,
          scrub: true,
          markers: true,
          id:"anim6"
        }); */
        const tl7 = gsap.timeline()
        .from(l13,{opacity:0, y:250, duration: 10, ease: 'none'})
        .to(l13,{opacity:0, y:-350, duration: 10, ease: 'none'})
        .from(l14,{opacity:0, y:150, duration: 10, ease: 'none'})
        .to(l14,{opacity:0, y:-350, duration: 10, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl7,
          trigger: m4,
          start: "-=0%",
          pin: true,
          scrub: true,
          markers: true,
          id:"anim7"
        });
       
        const tl9 = gsap.timeline()
        .from(l15,{opacity:0, y:-650, duration: 10, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl9,
          trigger: m6,
          start: "-=100%",
          end: "bottom bottom", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim9"
        });
        

      }, [])

    //scrolling the word cat

    
      
    

      
    return (
        <Layout pageTitle="Home Page" > 
            <div className="relative">
                    
                    <div className="flex flex-col items-end h-12 md:h-20 mb-12 md:mb-32 mr-12 md:mr-32 w-full h-screen fixed bottom-0 right-0">
                        <div className="flex flex-row items-center justify-center" ref={scrolling}>
                            <div className="text-[3.5vh] mx-6 font-extralight text-white">keep scrolling</div>  
                            <div>
                                <StaticImage className="h-12 md:h-20 w-12 md:w-20"  src="../images/Scoll.png"></StaticImage>
                            </div>
                        </div>
                    </div>  
            </div>
            <div className="min-w-full max-w-full bg-gradient-to-t from-purple3 to-pink4" >
                <section ref={movable0} className="flex flex-row justify-center items-center w-full h-screen">
                 
                <div ref={catlogo} className="flex flex-col justify-center items-center w-full h-screen">
                
                    <Tilt className={parallaxeffectimg}
                        tiltMaxAngleX={30}
                        tiltMaxAngleY={30}
                        perspective={1100}
                        transitionSpeed={100}
                        scale={1}
                        gyroscope={false}>
                  
                        {/* <div className={innerelement}>Happy Cat Space</div> */}
                    </Tilt>
                    
                </div>    
                  
                </section>
                      
                <section ref={movable1} className="relative flex flex-row justify-left items-center w-full h-screen">
                    
                            <div className="text-white leading-tight">
                            <div className="text-[8vw] md:text-[8vw] pl-[12vw] font-extralight" ref={lead1}>There are over</div>
                            <div className="text-[8vw] md:text-[8vw] pl-[12vw] font-extralight" ref={lead2}>100 Million</div>
                            <div className="flex flex-row pl-[12vw]" ref={div1}>
                                <div className="text-[8vw] md:text-[8vw] font-extralight" ref={lead3}>pet</div>
                                <div className="text-[8vw] md:text-[8vw] px-10 font-extralight" ref={spanCats}>cats</div>
                                
                                <div className="text-[8vw] md:text-[8vw] font-extralight" ref={lead4}>in the US</div> 
                                
                            </div>
                            </div>
                           
                        
                </section>
                <section ref={movable2} className="relative flex flex-row justify-left items-center w-full h-screen pl-[1vw]">
                    <div className="text-white self-center leading-tight font-extralight w-full">
                        <div className="flex flex-row">
                        <div className="text-[8vw] md:text-[8vw] px-10 opacity-0" ref={totarget}>cats</div>
                        </div>
                        <div className="text-[8vw] md:text-[8vw] pl-[10vw]" ref={lead5}>with loving parents</div>
                        <div className="text-[8vw] md:text-[8vw] pl-[10vw]" ref={lead6}>that celebrate</div>
                        <div className="text-[8vw] md:text-[8vw] pl-[10vw]" ref={lead7}>their birthdays</div>

                        



                        
                    </div>
                </section>
                <section ref={movable3} className="self-center w-full h-screen">
                    <div className="text-white self-center leading-tight font-extralight">
                        <div className="flex flex-row">
                        <div className="pl-[10vw] text-[30vh] px-10" ref={lead8}>but</div>
                        </div>
                        
                    </div>
                    <div className="text-white self-center leading-tight font-extralight">
                        <div className="flex flex-col">
                        <div className="pl-[40vw] text-[6vh] px-10" ref={lead9}>there are about</div>
                        <div className="pl-[40vw] text-[6vh] px-10" ref={lead10}>60 Million stray cats</div>
                        <div className="pl-[40vw] text-[6vh] px-10" ref={lead11}>that often not even reach</div>
                        <div className="pl-[40vw] text-[6vh] px-10" ref={lead12}>their first birthday</div>
                        
                        </div>
                        
                    </div>
                    <div ref={button1} className="flex flex-col items-end h-12 md:h-20 mb-12 md:mb-32 mr-12 md:mr-32 w-full">
                            <div className="flex flex-row items-center justify-center pr-32">
                                <div className="text-[3.5vh] mx-6 font-extralight text-white">learn more</div>  
                                <div>
                                    <StaticImage className="h-12 md:h-20 w-12 md:w-20"  src="../images/more.png"></StaticImage>
                                </div>
                            </div>
                        </div>  
                </section>
                <section ref={movable4} className="self-center w-full h-screen bg-white">
                    <div className="relative w-full h-screen flex flex-col items-center justify-center leading-tight font-extralight">
                            <div className="absolute text-[8vw] flex flex-row items-center justify-center w-full" ref={lead13}><div className="text-[8vw] text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4" >What if</div></div>
                            <div className="absolute flex flex-row items-center justify-center w-full text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4" ref={lead14}>
                                <div className="text-[8vw] " >Cats</div>
                                <div className="text-[8vw] px-10 text-transparent bg-clip-text bg-gradient-to-r from-purple3 to-pink4" >help</div>
                                <div className="text-[8vw] ">Cats</div>
                            </div> 
                            
                    </div>
                </section>
                <section ref={movable6} className="self-center">
                            <div className="relative flex flex-col w-full">
                                <div className="absolute flex flex-row justify-between self-center w-full text-white">
                                    <div className="text-[2vw] px-10 py-10 self-left" >Happy Cats Space</div>
                                    <div className="text-[2vw] px-10 py-10 self-right" >menu</div>
                                </div>   
                            </div>
                            <div className="flex items-center justify-center self-center w-full h-screen" ref={lead15}>
                
                                <Tilt className={parallaxeffectimg}
                                    tiltMaxAngleX={30}
                                    tiltMaxAngleY={30}
                                    perspective={1100}
                                    transitionSpeed={100}
                                    scale={1}
                                    gyroscope={false}>
                            
                                    {/* <div className={innerelement}>Happy Cat Space</div> */}
                                </Tilt>
                                
                            </div>    
                      
                    
                </section>
            </div>
     </Layout>
    )
}  
export default IndexPage